@import "~bootstrap/dist/css/bootstrap.css";

body {
    background: rgb(229, 227, 234)
}

input.yellow-input {
    border-bottom: 1px solid #fff !important;
    box-shadow: 0 1px 0 0 #fff !important;
}

input.yellow-input + label {
    color: #ffffff !important;
    margin-top: -3px;
}

input.yellow-input:focus + label {
    color: #ffeb3b !important;
    margin-top: -3px;
}

input.yellow-input:focus {
    border-bottom: 1px solid #ffeb3b !important;
    box-shadow: 0 1px 0 0 #ffeb3b !important;
}

.max-width-18-rem {
    max-width: 18rem !important;
}

.navbar, fieldset, .modal-body {
    background-color: beige;
}

#add-year, #back, #add-card, #add-bank, #set-currency, #settings, #to-main, #to-money {
    background-color: #c6c7c8;
    color: #111111;
}

.nav-item {
    width: auto;
}

.nav-name {
    width: 35%;
}

.title-card {
    white-space: nowrap;
    overflow: hidden;
}

a.active {
    background-color: #90caf9;
    color: white;
}

a.inactive {
    background-color: beige;
    color: #0d6efd;
}

.card {
    background-color: beige !important;
}

.folder, .link, .note {
    width: 6rem;
    margin: 1rem;
}

.form-link-detail {
    border: solid 1px #32606080;
}

#button-close-note {
    position: absolute;
    right: 1.3rem;
    top: 0.6rem;
    /*border: solid 1px #ccc;*/
    /*background-color: red;*/
}

#button-close-link {
    /*border: solid 1px #ccc;*/
    /*background-color: red;*/
}

#items {
    height: max-content;
}

.text-link {
    margin-left: 2px;
    margin-top: -45px;
    font-size: small;
    white-space: nowrap;
    transform: scaleX(0.8);
    width: 100%;
}

.div-name-link {
    margin-top: 26px;
}

.name-link, .name-note {
    text-decoration: none;
}

.name-folder, .name-link, .name-note, .item {
    margin-top: 0;
    max-width: 100%;
    overflow: hidden;
    font-size: small;
}

.exit {
    background-color: #c30c0c3b !important;
}

.nav-link {
    font-weight: 600;
    color: darkgreen;
}

.toast {
    width: max-content;
}

.navbar h4 {
    color: olive;
}

.avatar-auth {
    height: 50px;
    width: 50px;
    background-size: cover;
    background-image: url("images/avatar-default.png");
}

.avatar-not-auth {
    height: 50px;
    width: 50px;
    background-size: cover;
    background-image: url("images/icons8-link-128.png");
}

.avatar-profile {
    height: 50vw;
    width: 50vw;
    background-size: cover;
    background-image: url("images/avatar-default.png");
}

.avatar-image-current {
    height: 75vw;
    width: 75vw;
    background-size: cover;
    background-image: url("images/avatar-default.png");
}

.create-link-div {
    height: 140px;
}

.create-link {
    font-size: 130px;
}

.sort-clicks-up, .sort-clicks-down, .sort-count-up, .sort-count-down, .sort-name-up, .sort-name-down {
    font-size: inherit;
}

.sort-clicks, .sort-count, .sort-name {
    background-color: #f8f9fa;
    border-color: #c6c7c8;
}

.btn {
    opacity: 0.9;
}

.btn:hover {
    opacity: 1;
}

.btn-success:hover {
    background: #0b575e;
}

.max-width-500-px {
    max-width: 500px;
}

.form-detail {
    font-weight: bold;
}

.div-eye {
    border-radius: 0 5px 5px 0;
}

.form-detail-password {
    border-right: none;
}

.input-group-text {
    background-color: white;
}

.eye-detail {
    background-color: #e9ecef;
}

/* Profile page */

.profile-button {
    background: rgb(99, 39, 120);
    box-shadow: none;
    border: none
}

.profile-button:hover {
    background: #682773
}

.profile-button:focus {
    background: #682773;
    box-shadow: none
}

.profile-button:active {
    background: #682773;
    box-shadow: none
}

.back:hover {
    color: #682773;
    cursor: pointer
}

.labels {
    font-size: 11px
}

.add-experience:hover {
    background: #BA68C8;
    color: #fff;
    cursor: pointer;
    border: solid 1px #BA68C8
}

#password-old-profile, #password-new-profile, #password-repeat-profile, #password {
    border-right: none;
}

/* End Profile page*/

/* Start Timing accessToken */

.timer__items {
    display: flex;
    font-size: 18px;
}
.timer__item {
    position: relative;
    min-width: auto;
    margin-left: 10px;
    text-align: center;
    font-weight: 500;
}
.timer__item::before {
    content: attr(data-title);
    display: block;
    bottom: 0;
    font-size: 14px;
}
.timer__item:not(:last-child)::after {
    content: ':';
    position: absolute;
    right: -7px;
}

.timer__days:not(:last-child)::after {
    content: ' ';
    position: absolute;
    right: -127px;
}

/* End Timing accessToken */


/* Start context menu */

.context-menu-item, .context-menu-create, #context-menu-create {
    position: fixed;
    z-index: 10000;
    max-width: 200px;
    background: #f8f9fa;
    border-radius: 5px;
}

#img-context-menu {
    position: absolute;
    right: -0.4rem;
    top: -0.3rem;
    max-width: 25px;
}

.img-check {
    position: absolute;
    right: 3.5rem;
    top: -0.25rem;
    max-width: 25px;
}

.context-menu-body {
    width: min-content;
}

#context-menu-create .item {
    padding: 8px 10px;
    font-size: 15px;
    color: #111111;
    cursor: pointer;
    width: min-content;
}

.body-items {
    height: 100vh;
}

/* End context menu */

#row-up-to-level {
    margin-bottom: -1rem;
}

.card-note {
    background: rgb(0 0 0 / 3%);
}

.shared-icon {
    position: relative;
    /*margin: -1rem 0 -2rem 0;*/
}

.wifi {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0 auto;
}

.invisible {

}

/*Start Path*/
#nav-breadcrumb {
    --bs-breadcrumb-divider: '>';
}

.link-breadcrumb {
    opacity: 1;
}

.link-breadcrumb:hover, #navbar-nav li a:hover {
    background-color: #e9e98f;
}
/*End Path*/

.hand-click {
    cursor: pointer;
}

/* Search results */

.founded-items {
    height: 65vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.img-search {
    width: 15%;
}

#img-folder, #img-link, #img-note {
    width: 50px;
}

/* End search results */

/* Upload file */

#label-upload-file {
    cursor: pointer;
    /* Style as you please, it will become the visible UI component. */
}

#formFileMultiple {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

.input__wrapper {
    width: 100%;
    position: relative;
    /*margin: 15px -32px;*/
    text-align: center;
}

.input__file {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: -100px;
}

.input__file-icon-wrapper {
    height: 60px;
    width: 60px;
    margin-right: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-right: 1px solid #fff;
}

.input__file-button-text {
    line-height: 1;
    margin-top: 1px;
}

.input__file-button {
    width: 100%;
    /*max-width: 290px;*/
    /*height: 60px;*/
    background: #1bbc9b;
    color: #fff;
    font-size: 1.125rem;
    font-weight: 700;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*-webkit-box-align: center;*/
    /*-ms-flex-align: center;*/
    align-items: center;
    /*-webkit-box-pack: start;*/
    -ms-flex-pack: start;
    justify-content: flex-start;
    border-radius: 3px;
    cursor: pointer;
    margin: 0 auto;
}

/* End Upload file */

/* Start filter */

#filterInput {
    width: 20vw;
}

/* End filter */

/* Start Calendar */

#btn-close-calendar {
    font-size: small;
    margin: 20px 0;
    padding: 0;
}

.vertical-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
body {
    background: #efefef;
}
.row {
    display: flex;
    width: 100%;
}
.calendar {
    display: none;
    /*display: block;*/
    background: #ffffff;
    width: 300px;
    border: solid 1px #cccccc;
    /*margin: auto;*/
    box-shadow: 0 0 15px 0 #c0c0c0;
    /*font-size: 1.3rem;*/
    /*text-align: start;*/
    border-radius: 8px;
    padding: 0 20px;
    /*font-size: 16px;*/
    /*position: absolute;*/
    /*left: 100px;*/
    z-index: 1;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
}
.calendar header {
    /*display: flex;*/
    justify-content: center;
    align-items: center;
    cursor: default;
    font-size: 1.4rem;
    display: block;
    font-weight: bold;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #00abee;
}
.calendar header .month-display {
    align-items: center;
    height: 40px;
}
.calendar header .month-label {
    flex: 1;
    text-align: left;
    padding-left: 10px;
    font-size: 16px;
}
.calendar header .arrow {
    flex-basis: 6%;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.2s;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.calendar .week:first-child {
    border-top: none;
}
.calendar .day-names {
    color: #00abee;
    font-weight: bold;
    cursor: default;
    font-size: 16px;
}
.calendar .day-names .day {
    cursor: default;
}
.calendar .day-names .day:hover {
    background: inherit;
}
.calendar .day {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 35px;
    cursor: pointer;
    transition: all 0.2s;
    padding: 6px;
}
.calendar .day:hover {
    background: #efefef;
}
.calendar .day:first-child {
    border-left: none;
}
.calendar .day.today {
    background: #d5f3ff;
    border-radius: 50%;
    width: 35px;
    max-width: 35px;
    height: 35px;
}
.calendar .day.different-month {
    color: #c0c0c0;
}
.calendar .day.selected {
    background: #00abee;
    color: #ffffff;
    border-radius: 50%;
    width: 35px;
    max-width: 35px;
    height: 35px;
}

/* End Calendar */





@media (min-device-width: 760px) {
    .folder, .link, .note {
        width: 8rem;
        margin: 1rem;
    }
    .text-link {
        margin-left: 4px;
        margin-top: -62px;
        font-size: small;
        white-space: nowrap;
    }
    .div-name-link {
        margin-top: 43px;
    }
    .input-create-link {
        width: 75%;
    }
    .avatar-profile {
        max-height: 15vw;
        max-width: 15vw;
        background-size: cover;
        background-image: url("images/avatar-default.png");
    }

    .avatar-image-current {
        height: 25vw;
        width: 25vw;
        background-size: cover;
        background-image: url("images/avatar-default.png");
    }

    #img-context-menu {
        max-width: 30px;
    }

    .img-check {
        right: 5rem;
        max-width: 30px;
    }

    .year-income-expense {
        display: flex;
    }

    .moneyMain {
        width: 75%;
    }
}

@media (max-device-width: 760px) {

    #filterInput {
        width: 80vw;
    }

    #title-text, #filterInputName {
        font-size: 1.2rem;
    }

    .year-income-expense {
        display: flow;
    }

    .moneyMain {
        width: 100%;
    }

}